import { createWebHistory, createRouter } from "vue-router";

const routes = [
    {
      path: "/",
      name: "Home",
      component: () => import('@/pages/home/index.vue'),
    },
    {
      path: "/roadmap",
      name: "roadmap",
      component: () => import('@/pages/roadmap/index.vue'),
    },
    {
      path: "/mint",
      name: "mint",
      component: () => import('@/pages/mint/index.vue'),
    },
    {
      path: "/about",
      name: "about",
      component: () => import('@/pages/about/index.vue'),
    },
    {
      path: "/new",
      name: "new",
      component: () => import('@/pages/new/index.vue'),
    },
    {
      path: '/:pathMatch(.*)*',
      name: "404",
      component: () => import('@/pages/404.vue'),
    },
  ];
  
  const router = createRouter({
    history: createWebHistory(),
    routes,
  });
  
  export default router;
