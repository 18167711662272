<template>
  <div id="app">
    <component :is="layout">
      <Header />
      <router-view></router-view>
    </component>
  </div>
</template>
<script>
import Header from "./components/Header.vue";

export default {
  name: "app",
  components: {
    Header
  },
  computed: {
    layout() {
      return "default";
    },
  },
};
</script>