<template>
<nav class="navbar is-fixed-top is-black" role="navigation" aria-label="main navigation">
  <div class="navbar-brand">
    <a class="navbar-item" href="/">
      <img src="../assets/logo.png">
    </a>

<a role="button" class="navbar-burger" data-target="navMenu" aria-label="menu" aria-expanded="false">
  <span aria-hidden="true"></span>
  <span aria-hidden="true"></span>
  <span aria-hidden="true"></span>
</a>
  </div>

<div class="navbar-menu" id="navMenu">
    <div class="navbar-start is-size-4">
      <a class="navbar-item" href="/about">
        About us
      </a>

      <div class="navbar-item has-dropdown is-hoverable">
        <a class="navbar-link">
          More
        </a>

        <div class="navbar-dropdown is-size-4">
          <a class="navbar-item" href="/roadmap">
            🗺️ Roadmap
          </a>
          <a class="navbar-item">
            ☠️ Scoundrel
          </a>
          <hr class="navbar-divider">
        </div>
      </div>
    </div>

    <div class="navbar-end">
      <div class="navbar-item">
        <div class="buttons">
          <a class="button is-large is-dark" href="/mint">
            <strong>🦜 Mint!</strong>
          </a>
       <button class="button is-large" onclick="window.open( 'https://discord.gg/GWwx72PBBH', '_blank')" type="button">
          <span class="icon is-medium">
            <i class="fab fa-discord"></i>
          </span>
      </button>
      <button class="button is-large" onclick="window.open( 'https://twitter.com/potosnft', '_blank')" type="button">
    <span class="icon is-medium">
      <i class="fab fa-twitter"></i>
    </span>
  </button>
       <button class="button is-large" onclick="window.open( 'https://instagram.com/potosnft', '_blank')" type="button">
          <span class="icon is-medium">
            <i class="fab fa-instagram"></i>
          </span>
      </button>
        </div>

      </div>
    </div>
  </div>
</nav>
</template>
